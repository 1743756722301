<template>
  <v-select :items="items" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import { monitoringItemTypes } from '@/modules/object-types/compositions/constants';

export default {
  name: 'TypeSelector',
  props: {
    items: {
      type: Array,
      default: () => [monitoringItemTypes.string, monitoringItemTypes.number, monitoringItemTypes.bool]
    }
  }
};
</script>
